import React from 'react';
import Banner from './Banner';
import Card from './Card';

const Tokenomics: React.FC<unknown> = () => (
  <div>
    <h2 className=" text-center" id="tokenomics">
      Tokenomics
    </h2>
    <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row mt-24">
      <Banner
        imageUrl="images/orange-banner.png"
        label={{ value: `Supply`, color: `#982400` }}
        value="888,888,888,888"
      />
      <Banner
        imageUrl="images/yellow-banner.png"
        label={{ value: `Total LP in USDT`, color: `#885600` }}
        value="TBA"
      />
    </div>
    <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 mt-4 lg:mt-10">
      <Banner
        imageUrl="images/green-banner.png"
        label={{ value: `Circulating`, color: `#005E57` }}
        value="TBA"
      />
      <Banner
        imageUrl="images/pink-banner.png"
        label={{ value: `Burned`, color: `#5C0075` }}
        value="TBA"
      />
    </div>

    <div className="flex flex-col lg:flex-row mt-20 justify-between space-y-10 lg:space-y-0 pl-2  lg:mr-0 w-full space-x-0 lg:space-x-8">
      <Card
        imageUrl="images/liquidity.png"
        tag="4%"
        title="Liquidity"
        description="Every transaction will feed our liquidity pool with 3% of the transferred amount. This will prevent a lot of babies from creating mental havoc and crying."
      />
      <Card
        imageUrl="images/treasury.png"
        tag="3%"
        title="TREASURY"
        description="Every transaction will feed our treasury with 3% of the transferred amount. Babies are friendly with playmates, so loyal playmates have an equal chance to share some milk."
      />
      <div className=" mt-20 lg:mt-0">
        <Card
          imageUrl="images/marketing.png"
          tag="3%"
          title="Marketing"
          description="Every transaction will feed our marketing/dev budget with 3% of the transferred amount. This will help us to attract and welcome more playmates into our community."
        />
      </div>
    </div>
  </div>
);

export default Tokenomics;
