import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Community: React.FC<unknown> = () => (
  <div id="community" className="pt-8 pr-4 pl-2 lg:pt-16">
    <div className=" bg-black text-center lg:text-left">
      <div className="flex flex-col-reverse lg:flex-row space-y-reverse space-y-4 lg:space-y-0 justify-between py-4 lg:py-12 px-2 lg:px-24 relative bg-black bottom-2 left-2">
        <div>
          <h2>Community</h2>
          <p
            className=" mt-6 text-lg lg:text-5xl"
            style={{ maxWidth: 700, lineHeight: `1.1` }}
          >
            Come play with the rest of us in a white-box playground where
            creativity has no limits.
          </p>
          <div className="flex mt-10 lg:mt-24 space-x-4">
            <a href="/" target="_blank" rel="noreferrer">
              <StaticImage
                src="../../../static/images/telegram.png"
                alt="telegram"
                height={70}
              />
            </a>
            <a
              href="https://twitter.com/metababytoken"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../../../static/images/twitter.png"
                alt="twitter"
                height={70}
              />
            </a>
            <a href="/" target="_blank" rel="noreferrer">
              <StaticImage
                src="../../../static/images/medium.png"
                alt="medium"
                height={70}
              />
            </a>
            <a href="/" target="_blank" rel="noreferrer">
              <StaticImage
                src="../../../static/images/github.png"
                alt="github"
                height={70}
              />
            </a>
          </div>
        </div>
        <StaticImage
          src="../../../static/images/teddy.png"
          height={430}
          alt=""
        />
      </div>
    </div>
  </div>
);

export default Community;
