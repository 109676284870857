import React from 'react';

interface ItemProps {
  label: string;
  value: string;
}
const Item: React.FC<ItemProps> = ({ label, value }) => (
  <div className="flex items-center space-x-3 ">
    <div className=" h-2 w-2  bg-yellowButton inline-block" />
    <span className="" style={{ lineHeight: 1 }}>{`${label}: ${value}`}</span>
  </div>
);

export default Item;
