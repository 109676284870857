/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const NavBar: React.FC<unknown> = () => (
  <div className="flex flex-col lg:flex-row p-2 space-y-4 lg:space-y-0 content-center justify-between items-center">
    <img
      src="images/logo.png"
      alt="Logo"
      height={35}
      style={{ objectFit: `contain`, height: 35, marginTop: 9 }}
    />
    <div className="hidden lg:flex  items-center space-x-12">
      {[`HOME`, `ABOUT US`, `TOKENOMICS`, `BOTTLES`, `COMMUNITY`].map((el) => (
        <a
          key={el}
          className="bg-turkois "
          href={`#${el.split(` `).join(`-`).toLocaleLowerCase()}`}
        >
          <div className="bg-turkois hover:bg-turkoisLight relative top-1 right-1 px-6 py-2">
            {el}
          </div>
        </a>
      ))}
    </div>
    <div className="bg-pink" role="button">
      <span className=" block bg-pink py-2 px-6 relative top-1 right-1 ">
        CONNECT WALLET
      </span>
    </div>
  </div>
);

export default NavBar;
