import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const AboutUs: React.FC<unknown> = () => (
  <div>
    <h2 className="text-center" id="about-us">
      About Us
    </h2>
    <p
      className="mt-10 text-xl lg:text-5xl text-center "
      style={{ lineHeight: `1.3` }}
    >
      The first token to seamlessly switch between rewarding playmates with{' '}
      <span className="text-yellow-500 underline lg:text-5xl"> NFT </span>{' '}
      bottles which can contain a range of
      <span className="text-yellow-500 lg:text-5xl"> Metaverse</span> tokens and
      buy-backs.
    </p>
    <div className="flex space-x-4 lg:-space-x-0  justify-evenly items-baseline  my-12">
      <StaticImage src="../../../static/images/t.png" alt="" />
      <StaticImage src="../../../static/images/b.png" alt="" />
      <StaticImage src="../../../static/images/m.png" alt="" />
    </div>
    <p
      className=" mt-10 text-xl lg:text-5xl text-center"
      style={{ lineHeight: `1.3` }}
    >
      Stake your{' '}
      <span className="text-yellow-500 underline lg:text-5xl"> $METABABY </span>
      tokens for a chance of receiving a bottle (NFT) which grants access to
      milky Metaverse tokens - sell your tokenized bottles on secondary markets
      or be a big baby and consume yourself!
    </p>
  </div>
);

export default AboutUs;
