import React from 'react';
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
import { StaticImage } from 'gatsby-plugin-image';
import NavBar from '@/components/NavBar';
import Hero from '@/components/Hero';
import AboutUs from '@/components/AboutUs';
import Tokenomics from '@/components/Tokenomics';
import Bottle from '@/components/Bottles';
import Community from '@/components/Community';

const Home: React.FC<PageProps> = () => (
  <div style={{ overflowX: `hidden` }}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Meta Baby</title>
    </Helmet>
    <div
      style={{
        background: `url("images/first-bg.png")`,
        backgroundSize: `container`,
      }}
    >
      <div className="lg:container px-2 lg:px-0 pt-2 lg:pt-10 mt-2">
        <NavBar />
        <Hero />
      </div>
    </div>
    <StaticImage
      alt=""
      src="../../static/images/divider.png"
      style={{ width: `110vw`, left: `-10px`, position: `relative` }}
    />
    <div
      style={{
        background: `url("images/first-bg.png")`,
        backgroundSize: `container`,
      }}
    >
      <div className="lg:container px-2 pt-20 lg:pt-48 mt-2">
        <AboutUs />
      </div>
    </div>
    <div
      style={{ width: `fit-content` }}
      className=" bg-yellowButton mt-8 lg:mt-16 mb-12 lg:mb-28 mx-auto"
      role="button"
    >
      <span className=" bg-yellowButton hover:bg-yellow-300 px-16 py-5 text-xl relative bottom-1 right-1 block text-black">
        Whitepaper
      </span>
    </div>
    <div
      style={{
        background: `url("images/first-bg.png")`,
        backgroundSize: `container`,
      }}
    >
      <div className="lg:container px-2 pt-2 pb-32 lg:pb-64">
        <Tokenomics />
      </div>
    </div>
    <StaticImage
      alt=""
      src="../../static/images/divider.png"
      style={{ width: `110vw`, left: `-10px`, position: `relative` }}
    />
    <div
      style={{
        backgroundImage: `url("images/bottle-background.png")`,
        backgroundSize: `contain`,
      }}
    >
      <div className="lg:container px-2 -mt-2 pl-2 lg:pl-28 pb-10">
        <Bottle />
      </div>
      <StaticImage
        alt=""
        src="../../static/images/divider.png"
        style={{ width: `110vw`, left: `-10px`, position: `relative` }}
      />
    </div>
    <div
      style={{
        background: `url("images/first-bg.png")`,
        backgroundSize: `container`,
      }}
    >
      <div className="lg:container pt-2 pb-20 lg:pb-64">
        <Community />
      </div>
    </div>
    <div className="lg:container flex justify-center lg:justify-between items-center pb-10">
      <span>Copyright © Meta Baby, 2021</span>
      <div className="hidden lg:flex  items-center space-x-12">
        {[`HOME`, `ABOUT US`, `TOKENOMICS`, `BOTTLES`, `COMMUNITY`].map(
          (el) => (
            <a
              key={el}
              className="bg-turkois "
              href={`#${el.split(` `).join(`-`).toLocaleLowerCase()}`}
            >
              <div className="bg-turkois hover:bg-turkoisLight relative top-1 right-1 px-6 py-2">
                {el}
              </div>
            </a>
          ),
        )}
      </div>
    </div>
  </div>
);

export default Home;
