import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Hero: React.FC<unknown> = () => (
  <div className="flex flex-col-reverse lg:flex-row mt-16 lg:mt-32 pb-20 lg:pb-64 space-y-reverse space-y-10 lg:space-y-0  justify-between text-center lg:text-left px-2">
    <div className="flex flex-col justify-center" style={{ maxWidth: 720 }}>
      <h1 className="mb-0">
        METABABY represents a global acceptance of the Metaverse and a
        people-powered future.``
      </h1>
      <p
        className="text-gray-400 text-base mt-7 mb-8"
        style={{ maxWidth: 538 }}
      >
        Participate and play with the meta baby by staking your
        <span className=" text-yellow-400"> $METABABY</span> tokens and convince
        it to share its tokenized bottle with you or another playmate.
      </p>

      <div className="flex  space-x-4 lg:space-x-8">
        <a href="/">
          <div className=" bg-turkois" role="button">
            <span className=" bg-turkoisLight hover:bg-turkois px-4 lg:px-10 py-2 lg:py-5 text-xl relative bottom-1 right-1 block">
              BUY ON PANCAKESWAP
            </span>
          </div>
        </a>
        <a href="/">
          <div className=" bg-yellowButton" role="button">
            <span className=" bg-yellowButton hover:bg-yellow-300 px-4 lg:px-10 py-2 lg:py-5 text-xl relative bottom-1 right-1 block text-black">
              VIEW ON DEXTOOLS
            </span>
          </div>
        </a>
      </div>
    </div>
    <StaticImage
      src="../../../static/images/baby.png"
      alt="baby"
      height={500}
      className="mr-0 lg:mr-5  2xl:mr-20"
    />
  </div>
);

export default Hero;
