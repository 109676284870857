import React from 'react';
import Item from './Item';

const Bottle: React.FC<unknown> = () => (
  <div id="bottles" className="pt-12 lg:pt-24">
    <div className=" flex flex-col lg:flex-row justify-between items-center">
      <div className="flex flex-col">
        <h2>BOTTLES</h2>
        <p className="lg:text-5xl mt-6 mb-8 lg:mb-16" style={{ maxWidth: 665 }}>
          Check if there is anymore tokens left in a bottle and consume it like
          a baby!
        </p>
        <div className="pl-0 lg:pl-6">
          <div className="flex flex-col space-y-3 lg:space-y-4">
            <Item label="Owner" value="wallet Address" />
            <Item label="Donated" value="wallet Address" />
            <Item label="Tokenized Milk" value="0 $METAHERO" />
            <Item label="Consumed" value="True" />
          </div>
          <div className="flex flex-col lg:flex-row pt-8 lg:pt-12 space-y-4 lg:space-y-0 space-x-0 lg:space-x-4 text-center lg:text-left">
            <div className=" bg-black">
              <input
                placeholder="Enter Token ID"
                className="relative bg-black h-14 lg:h-full w-full pl-4 bottom-1 right-1"
                type="text"
              />
            </div>
            <div className=" bg-turkois" role="button">
              <span className=" bg-turkoisLight hover:bg-turkois px-6 py-3 text-xl relative bottom-1 right-1 block text-black">
                LOOKUP
              </span>
            </div>
            <div className=" bg-yellowButton" role="button">
              <span className=" bg-yellowButton hover:bg-yellow-300 px-6 py-3 text-xl relative bottom-1 right-1 block text-black">
                CONSUME
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block" style={{ height: 560 }}>
        <img src="images/bottles-of-milk.png" alt="" style={{ height: 560 }} />
      </div>
    </div>
  </div>
);

export default Bottle;
