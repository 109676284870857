import React from 'react';

interface BannerProps {
  imageUrl: string;
  label: {
    color: string;
    value: string;
  };
  value: string | number;
}
const Banner: React.FC<BannerProps> = ({ imageUrl, label, value }) => (
  <div className="relative items-center w-full mr-5 " style={{ height: 100 }}>
    <img
      className="absolute w-full"
      src={imageUrl}
      height={100}
      alt=""
      style={{ height: 100 }}
    />
    <div className="flex flex-col lg:flex-row justify-evenly lg:justify-between pl-12 lg:pl-24 pr-12 items-center w-full h-full">
      <span
        className="text-2xl uppercase font-bold z-10"
        style={{ color: label.color }}
      >
        {label.value}
      </span>
      <span className="text-4xl z-10">{value}</span>
    </div>
  </div>
);

export default Banner;
