import React from 'react';

interface CardProps {
  imageUrl: string;
  tag: string;
  title: string;
  description: string;
}
const Card: React.FC<CardProps> = ({ imageUrl, tag, title, description }) => (
  <div className=" h-auto bg-sand text-center">
    <div className="flex flex-col items-center w-full h-full relative bg-sand bottom-5 right-2 px-2 lg:px-10">
      <img
        className="mt-7 lg:mt-14 mb-3 lg:mb-6"
        src={imageUrl}
        alt={title}
        height={230}
        style={{ height: 230 }}
      />
      <span style={{ color: `#9F6603`, fontSize: 48, lineHeight: 1 }}>
        {tag}
      </span>
      <h3 className="mt-4 uppercase">{title}</h3>
      <p className="text-base mt-5" style={{ maxWidth: 380, color: `#001428` }}>
        {description}
      </p>
    </div>
  </div>
);

export default Card;
